import { MESSAGE_TEMPLATES_FILTERS_MAPPING, MESSAGE_TEMPLATES_SORTING_MAPPING, TEMPLATES_PER_PAGE, } from '@backoffice/types/store/templates';
import { fetchResourceCollection, newListFilters, newListSorting, SortDirection, } from '@backoffice/utils/api';
import { ApiResourceName } from '@shared/types/api/api';
export const name = 'templates';
export const namespaced = true;
export const state = () => ({
    templates: [],
    total: 0,
    loading: false,
    page: 1,
    filters: newListFilters(),
    sorting: newListSorting('title', SortDirection.Asc),
});
export const getters = {
    perPage() {
        return TEMPLATES_PER_PAGE;
    },
    totalPages(state, getters) {
        return Math.ceil(state.total / getters.perPage);
    },
};
export const actions = {
    async loadTemplates({ commit, state }) {
        commit('SET_LOADING', true);
        try {
            const result = await fetchResourceCollection(ApiResourceName.MessageTemplates, this.$axios, state.page, [state.filters, MESSAGE_TEMPLATES_FILTERS_MAPPING], [state.sorting, MESSAGE_TEMPLATES_SORTING_MAPPING]);
            commit('SET_TEMPLATES', result.data);
            commit('SET_TOTAL', result.total);
        }
        catch (err) {
            commit('SET_TEMPLATES', []);
            commit('SET_TOTAL', 0);
        }
        finally {
            commit('SET_LOADING', false);
        }
    },
    async changePage({ commit, dispatch }, page) {
        commit('SET_PAGE', page);
        await dispatch('loadTemplates');
    },
    async filter({ commit, dispatch }, filters) {
        commit('SET_FILTERS', filters);
        await dispatch('loadTemplates');
    },
    async sort({ commit, dispatch }, sorting) {
        commit('SET_SORTING', sorting);
        await dispatch('loadTemplates');
    },
};
export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_TEMPLATES(state, templates) {
        state.templates.splice(0, state.templates.length, ...templates);
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_FILTERS(state, filters) {
        for (const key in filters)
            state.filters[key] = filters[key];
    },
    SET_SORTING(state, sorting) {
        state.sorting.by = sorting.by;
        state.sorting.direction = sorting.direction;
    },
    RESET_FILTERS(state) {
        for (const key in state.filters)
            state.filters[key] = undefined;
    },
    RESET_SORTING(state) {
        state.sorting.by = 'title';
        state.sorting.direction = SortDirection.Asc;
    },
};
