import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { entitiesChangesAreEmpty, entitiesChangesToPayloads, ENTITY_CHANGES_CLAIM_PROPERTY, getEntitiesForType, newEntitiesChanges, } from '@backoffice/claim/edition/entitiesChanges';
import { createSerializationQueryParam } from '@shared/constants/serializationKeys';
import { SERIALIZATION_KEY_BACKOFFICE } from '@backoffice/constants/serializationKeys';
import { serialize } from '@shared/serialization/apiResourceSerializer';
import { ApiResourceName } from '@shared/types/api/api';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { uuidFromIri } from '@shared/utils/iri';
import { mergePayload } from '@shared/utils/mergePayload';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
export function newState() {
    return {
        id: null,
        info: null,
        initialClaim: null,
        isInfoLoading: false,
        isEditingClaim: false,
        isSavingChanges: false,
        isContractLoading: false,
        contract: null,
        error: null,
        isUpdatingDraft: false,
        draftPayload: null,
        openClaimIndicator: null,
        entitiesChanges: newEntitiesChanges(),
        hasPendingChanges: false,
        heliosDeclaration: null,
        heliosError: null,
        contractData: null,
        provision: null,
        otherFees: null,
        forcedExit: null,
        priming: null,
        magicLink: null,
    };
}
export function getEntityById(entities, id) {
    return entities.find((entity) => entity.id === id) || null;
}
export function emptyEntityGetter() {
    return null;
}
export function entitiesAndChangeKeys(initialClaim, claim, type) {
    const initialEntitiesIds = new Set(getEntitiesForType(type, initialClaim)
        .filter((entity) => entity.id)
        .map((entity) => uuidFromIri(entity.id)));
    const created = [];
    const updated = [];
    const draftEntities = getEntitiesForType(type, claim);
    let createdChangeKey = 0;
    for (const entity of draftEntities) {
        let id = null;
        if (entity.id)
            id = uuidFromIri(entity.id);
        if (!id || !initialEntitiesIds.has(id)) {
            created.push({
                changeKey: createdChangeKey++,
                entity,
            });
            continue;
        }
        updated.push({
            changeKey: uuidFromIri(id),
            entity,
        });
    }
    return [...updated, ...created];
}
/**
 * For consistent ordering in backoffice, we need to order entities by id
 */
export function orderEntitiesByIds(claim) {
    for (const path of Object.values(ENTITY_CHANGES_CLAIM_PROPERTY)) {
        const entities = get(claim, path, null);
        if (!entities) {
            continue;
        }
        entities.sort((a, b) => uuidFromIri(a.id).localeCompare(uuidFromIri(b.id)));
    }
}
export async function fetchClaim(claimIri, $axios, identifier) {
    var _a;
    const uuid = identifier ? claimIri : uuidFromIri(claimIri);
    const path = '/claims-brms' +
        (identifier ? `/${identifier}/` : '/') +
        `${uuid}?${createSerializationQueryParam(SERIALIZATION_KEY_BACKOFFICE)}`;
    const claim = await $axios.$get(path);
    orderEntitiesByIds(claim);
    let heliosDeclaration = null;
    let heliosError = null;
    if (((_a = claim.type) === null || _a === void 0 ? void 0 : _a.trigram) === ClaimTypeTrigram.Drought) {
        try {
            const heliosData = await $axios.$get('/helios/' + uuidFromIri(claim.id));
            heliosDeclaration = { ...heliosData, '@type': ApiEntityType.HeliosDeclaration };
        }
        catch (err) {
            heliosError = 'Erreur lors de la récupération des données Hélios';
            if (err instanceof Error)
                heliosError += ' : ' + err.message;
        }
    }
    return { claim, heliosDeclaration, heliosError };
}
export function fetchClaimContract(claimIri, $axios) {
    return $axios.$get(`${apiResourcePath(ApiResourceName.Claims)}/${uuidFromIri(claimIri)}/contract`);
}
export function fetchClaimContractData(claimIri, $axios) {
    return $axios.$get(`/contract-data/${uuidFromIri(claimIri)}`);
}
export async function putClaim(persist, $axios, state) {
    const { id, info, entitiesChanges, initialClaim } = state;
    if (!id || !info || !initialClaim) {
        throw new Error('Cannot save claim without state id or info');
    }
    const uuid = uuidFromIri(id);
    const path = persist
        ? `/claims-brms/${uuid}?${createSerializationQueryParam(SERIALIZATION_KEY_BACKOFFICE)}`
        : `/claims-brms-draft/${uuid}?${createSerializationQueryParam(SERIALIZATION_KEY_BACKOFFICE)}`;
    // We need to base payload generation (deleted/updated/created) on
    // the real backoffice Claim entities, not the ones in state.
    // We replace the Claim from state with the one from backend
    // for payload generation and PUT operation on collections
    const infoWithCurrentClaim = { ...info, claim: initialClaim };
    const entitiesPayload = entitiesChangesToPayloads(entitiesChanges, infoWithCurrentClaim);
    const payload = mergePayload(state.draftPayload, entitiesPayload);
    const serialized = serialize(payload);
    const claim = await $axios.$put(path, serialized);
    orderEntitiesByIds(claim);
    return claim;
}
export function stateHasPendingChanges(state) {
    return !entitiesChangesAreEmpty(state.entitiesChanges) || !isEmpty(state.draftPayload);
}
export async function draftChanges({ commit, state }, $axios) {
    if (!state.info || !state.id || !state.initialClaim) {
        return;
    }
    if (!state.isUpdatingDraft) {
        commit('SET_UPDATING_DRAFT', true);
    }
    try {
        const draft = await putClaim(false, $axios, state);
        commit('SET_INFO_CLAIM', draft);
        commit('SET_UPDATING_DRAFT', false);
    }
    catch (err) {
        commit('SET_UPDATING_DRAFT', false);
        throw err;
    }
}
