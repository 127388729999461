/* eslint-disable no-console */
import Vue from 'vue';
import { draftChanges, entitiesAndChangeKeys, fetchClaim, fetchClaimContract, fetchClaimContractData, getEntityById, putClaim, stateHasPendingChanges, } from '@backoffice/store/claim/claimStoreHelpers';
import { applyEntityChange } from '@backoffice/claim/edition/applyEntityChange';
import { entityChangeByChangeKey, getEntitiesForType, newEntitiesChanges, } from '@backoffice/claim/edition/entitiesChanges';
import { computeEntitiesAndFields, touchAndValidateEntity, undoEntityChange, validateAndRevealEntity, } from '@backoffice/claim/edition/entitiesEdition';
import { claimModelToFieldsContext } from '@backoffice/components/claim/claimModelToFieldsContext';
import { EntityChangeAction, isCreateChangeKey } from '@shared/claim/entities';
import { createSerializationQueryParam } from '@shared/constants/serializationKeys';
import { SERIALIZATION_KEY_BACKOFFICE } from '@backoffice/constants/serializationKeys';
import { MODEL_TO_FIELDS_FOR_TYPE } from '@shared/modelField/modelField';
import { coveringCategoryLabelsFromInfo } from '@shared/roomDamage/constants';
import { groupCoveringCategoriesBySurfaceType, } from '@shared/roomDamage/surfaceDamage';
import { ApiResourceName, } from '@shared/types/api/api';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import { newClientIdentifier } from '@shared/utils/clientIdentifier';
import { indexBy } from '@shared/utils/indexBy';
import { idFromIri, uuidFromIri } from '@shared/utils/iri';
import { mergePayload } from '@shared/utils/mergePayload';
import { CONFIG } from '@shared/environment/environment';
import { validateRole } from '@backoffice/types/security';
import { UserRole } from '@shared/types/api/user';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
export const name = 'claim';
export const namespaced = true;
export const state = () => ({
    id: null,
    info: null,
    initialClaim: null,
    isInfoLoading: false,
    isEditingClaim: false,
    isSavingChanges: false,
    isContractLoading: false,
    contract: null,
    error: null,
    isUpdatingDraft: false,
    draftPayload: null,
    openClaimIndicator: null,
    entitiesChanges: newEntitiesChanges(),
    hasPendingChanges: false,
    heliosDeclaration: null,
    heliosError: null,
    contractData: null,
    provision: null,
    otherFees: null,
    forcedExit: null,
    priming: null,
    magicLink: null,
});
/**
 * For this store, getters, actions, and mutations are separated for easier testing
 */
export const getters = {
    isClaimUpdating(state) {
        return state.isUpdatingDraft || state.isSavingChanges;
    },
    getEntity(state) {
        return (type) => {
            return (changeKey) => {
                if (!state.info || !state.info.claim)
                    return null;
                if (isCreateChangeKey(changeKey)) {
                    return state.entitiesChanges[type].create[changeKey !== null && changeKey !== void 0 ? changeKey : 0].data;
                }
                const updated = state.entitiesChanges[type].update[changeKey];
                const drafted = getEntityById(getEntitiesForType(type, state.info.claim), changeKey);
                return { ...drafted, ...updated };
            };
        };
    },
    modelFieldsContext({ info, contract, contractData }, _getters, { settings: { settings }, auth: { user } }) {
        return info
            ? (translate, edition) => claimModelToFieldsContext({ info, contract, contractData, settings, user }, translate, edition)
            : () => null;
    },
    validationConstraintsContext(state, _getters, rootState) {
        var _a, _b, _c, _d, _e, _f;
        if (!state.info || !state.initialClaim || !rootState.settings)
            return null;
        let externalDamageOrigin = null;
        if (((_a = state.info.claim.type) === null || _a === void 0 ? void 0 : _a.trigram) === ClaimTypeTrigram.StormSnowHail) {
            externalDamageOrigin = (_c = (_b = state.info.claim.stormSnowHailDamageQualification) === null || _b === void 0 ? void 0 : _b.causeType) !== null && _c !== void 0 ? _c : null;
        }
        if (((_d = state.info.claim.type) === null || _d === void 0 ? void 0 : _d.trigram) === ClaimTypeTrigram.Fire) {
            externalDamageOrigin = ClaimTypeTrigram.Fire;
        }
        return {
            typeTrigram: (_f = (_e = state.info.claim.type) === null || _e === void 0 ? void 0 : _e.trigram) !== null && _f !== void 0 ? _f : ClaimTypeTrigram.Empty,
            settings: rootState.settings.settings,
            personalPropertyCategories: state.info.personalPropertyCategories.map((cat) => {
                return {
                    data: cat,
                    subcategories: [],
                };
            }),
            externalDamageOrigin,
        };
    },
    entitiesAndFields(state, getters, rootState) {
        return (type, translate) => {
            if (!state.info || !state.initialClaim)
                return [];
            return computeEntitiesAndFields({
                existingEntities: entitiesAndChangeKeys(state.initialClaim, state.info.claim, type),
                modelFieldsContext: claimModelToFieldsContext({
                    info: state.info,
                    contract: state.contract,
                    contractData: state.contractData,
                    user: rootState.auth.user,
                }, translate),
                modelToFields: MODEL_TO_FIELDS_FOR_TYPE[type],
                changes: state.entitiesChanges[type],
                validationConstraintsContext: getters.validationConstraintsContext,
            });
        };
    },
    coveringCategoryLabelsByIri(state) {
        var _a, _b;
        return coveringCategoryLabelsFromInfo({
            coveringCategoriesByIri: (_b = (_a = state.info) === null || _a === void 0 ? void 0 : _a.coveringCategoriesByIri) !== null && _b !== void 0 ? _b : null,
        });
    },
    typeTrigram(state) {
        var _a, _b, _c;
        return (_c = (_b = (_a = state.info) === null || _a === void 0 ? void 0 : _a.claim.type) === null || _b === void 0 ? void 0 : _b.trigram) !== null && _c !== void 0 ? _c : ClaimTypeTrigram.Empty;
    },
    provisionAmount(state) {
        if (state.info) {
            return state.info.claim.provision;
        }
        return null;
    },
    otherFeesAmount(state) {
        if (state.info) {
            return state.info.claim.otherFees;
        }
        return null;
    },
    isPersonalPropertyLimitExceeded(state) {
        if (state.info && state.info.claim.robberyDamageQualification) {
            return state.info.claim.robberyDamageQualification.morePersonalPropertiesThanLimit === true;
        }
        return false;
    },
};
export const actions = {
    edit({ commit }) {
        commit('EDIT');
    },
    async selectClaim({ commit, state, rootState }, { id, edit, identifier }) {
        var _a, _b, _c, _d, _e;
        const info = (_a = state.info) !== null && _a !== void 0 ? _a : {};
        let claimFetchResult;
        try {
            claimFetchResult = await fetchClaim(id, this.$axios, identifier);
            id = uuidFromIri(claimFetchResult.claim.id);
        }
        catch (err) {
            console.error(err);
            return commit('SET_ERROR', 'Impossible de récupérer les informations de cette déclaration');
        }
        commit('RESET');
        commit('SELECT_CLAIM', id);
        if (edit)
            commit('EDIT');
        const fetchCompensationTable = this.$axios
            .$get(`/compensation_presentation/${id}`)
            .then((response) => response);
        const fetchTypesIfNotSet = info.types && info.types.length > 0
            ? Promise.resolve(info.types)
            : this.$axios
                .$get(apiResourcePath(ApiResourceName.ClaimTypes))
                .then((response) => response['hydra:member']);
        const fetchHousingTypesIfNotSet = info.housingTypes && info.housingTypes.length > 0
            ? Promise.resolve(info.housingTypes)
            : this.$axios
                .$get(apiResourcePath(ApiResourceName.HousingTypes))
                .then((response) => response['hydra:member']);
        const fetchHousingCapacitiesIfNotSet = info.housingCapacities && info.housingCapacities.length > 0
            ? Promise.resolve(info.housingCapacities)
            : this.$axios
                .$get(apiResourcePath(ApiResourceName.HousingCapacities))
                .then((response) => response['hydra:member']);
        const fetchInsuranceCompanyListIfNotSet = info.insuranceCompanyList && info.insuranceCompanyList.length > 0
            ? Promise.resolve(info.insuranceCompanyList)
            : this.$axios
                .$get(`${apiResourcePath(ApiResourceName.InsuranceCompanies)}?isFromReferentialList=true&isIARD=true`)
                .then((response) => response['hydra:member']);
        const fetchPersonalPropertyCategoriesIfNotSet = info.personalPropertyCategories && info.personalPropertyCategories.length > 0
            ? Promise.resolve(info.personalPropertyCategories)
            : this.$axios
                .$get(apiResourcePath(ApiResourceName.PersonalPropertyCategories))
                .then((response) => response['hydra:member'].sort((a, b) => a.label.localeCompare(b.label)));
        const fetchContractPersonalPropertyCategoriesIfNotSet = state.contractData &&
            state.contractData.contractPersonalPropertyCategories &&
            state.contractData.contractPersonalPropertyCategories.length > 0
            ? Promise.resolve(state.contractData.contractPersonalPropertyCategories)
            : this.$axios
                .$get('/contract-data/' + id + '/personal-property-categories')
                .then((response) => response.sort((a, b) => a.id.localeCompare(b.id)));
        const fetchCoveringCategoriesIfNotSet = info.coveringCategoriesBySurfaceType && info.coveringCategoriesByIri
            ? Promise.resolve([info.coveringCategoriesBySurfaceType, info.coveringCategoriesByIri])
            : this.$axios
                .$get(apiResourcePath(ApiResourceName.CoveringCategories))
                .then((response) => [
                groupCoveringCategoriesBySurfaceType(response['hydra:member']),
                Object.fromEntries(response['hydra:member'].map((category) => [
                    category.id,
                    category,
                ])),
            ]);
        const hasValuationOptionsRole = validateRole((_c = (_b = rootState.auth.user) === null || _b === void 0 ? void 0 : _b.roles) !== null && _c !== void 0 ? _c : [], UserRole.VALUATION_OPTIONS);
        const fetchAvailableRoomOptionsIfNotSet = hasValuationOptionsRole
            ? ((_d = info.availableRoomOptions) === null || _d === void 0 ? void 0 : _d.length)
                ? Promise.resolve(info.availableRoomOptions)
                : this.$axios
                    .$get(`/available_optional_allowances/room/${id}`)
                    .then((response) => response.optionsAllowances)
            : Promise.resolve([]);
        const fetchAvailableSurfaceOptionsIfNotSet = hasValuationOptionsRole
            ? ((_e = info.availableSurfaceOptions) === null || _e === void 0 ? void 0 : _e.length)
                ? Promise.resolve(info.availableSurfaceOptions)
                : fetchCoveringCategoriesIfNotSet.then(async ([, coveringCategoriesByIri]) => {
                    const buildAllDamageCategoryIdsList = (input) => {
                        return input.map((value) => idFromIri(value)).join('|');
                    };
                    const res = await this.$axios
                        .$get(`/available_optional_allowances/damageCategory/CoveringCategory/ids/${buildAllDamageCategoryIdsList(Object.keys(coveringCategoriesByIri))}`)
                        .then((result) => result
                        .map((v) => [v.iri, v.options.optionsAllowances])
                        .filter(([, optionsAllowances]) => optionsAllowances.length > 0));
                    return Object.fromEntries(res);
                })
            : Promise.resolve({});
        try {
            const [compensationTable, types, housingTypes, housingCapacities, insuranceCompanyList, personalPropertyCategories, contractPersonalPropertyCategories, [coveringCategoriesBySurfaceType, coveringCategoriesByIri], availableRoomOptions, availableSurfaceOptions,] = await Promise.all([
                fetchCompensationTable,
                fetchTypesIfNotSet,
                fetchHousingTypesIfNotSet,
                fetchHousingCapacitiesIfNotSet,
                fetchInsuranceCompanyListIfNotSet,
                fetchPersonalPropertyCategoriesIfNotSet,
                fetchContractPersonalPropertyCategoriesIfNotSet,
                fetchCoveringCategoriesIfNotSet,
                fetchAvailableRoomOptionsIfNotSet,
                fetchAvailableSurfaceOptionsIfNotSet,
            ]);
            // Load contract data asyncronously as it is a long query
            fetchClaimContractData(id, this.$axios).then((contractData) => {
                // Cancel if another claim has been selected while contract data was loading
                if (id !== state.id) {
                    return;
                }
                commit('SET_CONTRACT_DATA', contractData);
            });
            // Load contract asyncronously as it is a long query
            fetchClaimContract(id, this.$axios).then(async (contract) => {
                // Cancel if another claim has been selected while contract was loading
                if (id !== state.id) {
                    return;
                }
                commit('SET_CONTRACT', contract);
                const url = `${CONFIG.apiUrl}/claims/${claimFetchResult.claim.caseNumber}/magic-link`;
                const response = await this.$axios.$post(url, { contractNumber: contract.number });
                commit('SET_MAGIC_LINK', response.magicLink);
            });
            const info = {
                claim: claimFetchResult.claim,
                compensationTable,
                types,
                housingTypes,
                housingCapacities,
                insuranceCompanyList,
                personalPropertyCategories,
                personalPropertyCategoriesByIri: indexBy(personalPropertyCategories, 'id'),
                coveringCategoriesBySurfaceType,
                coveringCategoriesByIri,
                availableRoomOptions,
                availableSurfaceOptions,
            };
            const contractData = {
                contractPersonalPropertyCategories,
            };
            commit('SET_INITIAL_CLAIM', claimFetchResult);
            commit('SET_INFO', info);
            commit('SET_CONTRACT_DATA', contractData);
        }
        catch (err) {
            console.error(err);
            return commit('SET_ERROR', 'Impossible de récupérer les informations de cette déclaration');
        }
    },
    async cancelEdition({ commit, state }) {
        commit('SET_UPDATING_DRAFT', true);
        if (!state.id || !state.info) {
            commit('CANCEL_EDITION');
            commit('SET_UPDATING_DRAFT', false);
        }
        else {
            const claimFetchResult = await fetchClaim(state.id, this.$axios);
            commit('CANCEL_EDITION');
            commit('SET_INITIAL_CLAIM', claimFetchResult);
            commit('SET_INFO_CLAIM', claimFetchResult.claim);
            commit('SET_UPDATING_DRAFT', false);
        }
    },
    async updateDraftPayload({ commit, state }, payload) {
        commit('UPDATE_DRAFT_PAYLOAD', payload);
        await draftChanges({ commit, state }, this.$axios);
    },
    addDraftChanges({ commit }, payload) {
        commit('UPDATE_DRAFT_PAYLOAD', payload);
    },
    async draftChanges({ commit, state }) {
        if (state.id && state.info)
            await draftChanges({ commit, state }, this.$axios);
    },
    async saveChanges({ commit, dispatch, state }) {
        if (!state.id || !state.info) {
            return;
        }
        commit('SET_SAVING_CHANGES', true);
        try {
            const updatedClaim = await putClaim(true, this.$axios, state);
            const newInitialClaim = await fetchClaim(state.id, this.$axios);
            const compensationTable = await this.$axios
                .$get(`/compensation_presentation/${state.id}`)
                .then((response) => response);
            const newInfo = { ...state.info, compensationTable };
            commit('SET_INFO', newInfo);
            commit('RESET_CHANGES');
            commit('SET_INFO_CLAIM', updatedClaim);
            commit('SET_INITIAL_CLAIM', newInitialClaim);
            commit('SET_SAVING_CHANGES', false);
            commit('CANCEL_EDITION');
            dispatch('claims/loadClaims', undefined, { root: true });
        }
        catch (error) {
            commit('SET_SAVING_CHANGES', false);
            throw error;
        }
    },
    openIndicator({ commit }, indicator) {
        commit('OPEN_INDICATOR', indicator);
    },
    closeIndicator({ commit }) {
        commit('CLOSE_INDICATOR');
    },
    createEntity({ commit, state }, { type, payload }) {
        const changeKey = state.entitiesChanges[type].create.length;
        commit('ADD_ENTITY_CHANGE', {
            action: EntityChangeAction.Create,
            type,
            payload: {
                ...payload,
                clientIdentifier: newClientIdentifier(),
            },
            changeKey,
        });
        return changeKey;
    },
    undoEntityChange({ commit, state }, payload) {
        if (state.info)
            commit('UNDO_ENTITY_CHANGE', payload);
    },
    updateEntity({ commit, state }, { type, changeKey, payload, modelKey, entity, constraints }) {
        if (state.info) {
            commit('ADD_ENTITY_CHANGE', {
                action: EntityChangeAction.Update,
                type,
                payload,
                changeKey,
            });
            commit('TOUCH_AND_VALIDATE', {
                type,
                changeKey,
                modelKey,
                entity,
                constraints,
            });
        }
    },
    validateAndRevealEntity({ commit, state }, { type, changeKey, constraints, entity }) {
        if (state.info) {
            const entityChanges = state.entitiesChanges[type];
            const change = entityChangeByChangeKey(entityChanges, changeKey);
            if (!change) {
                commit('ADD_ENTITY_CHANGE', {
                    action: EntityChangeAction.Update,
                    type,
                    payload: {},
                    changeKey,
                });
            }
            commit('VALIDATE_AND_REVEAL', {
                type,
                changeKey,
                entity,
                constraints,
            });
        }
    },
    deleteEntity({ commit, state }, { type, changeKey }) {
        if (state.info) {
            commit('ADD_ENTITY_CHANGE', {
                action: EntityChangeAction.Delete,
                type,
                changeKey,
            });
        }
    },
    async saveNote({ state, commit }, note) {
        if (!state.id)
            throw new Error('Cannot save note without id in claim state');
        try {
            await this.$axios.put(`/claims-brms/${uuidFromIri(state.id)}?${createSerializationQueryParam(SERIALIZATION_KEY_BACKOFFICE)}`, { note });
            commit('SET_NOTE', note);
        }
        catch (error) {
            return commit('SET_ERROR', 'Impossible de sauvegarder la note');
        }
    },
    async saveOtherGuaranteeComment({ state, commit }, otherGuaranteeComment) {
        if (!state.id)
            throw new Error('Cannot save comment without id in claim state');
        try {
            await this.$axios.put(`/claims-brms/${uuidFromIri(state.id)}?${createSerializationQueryParam(SERIALIZATION_KEY_BACKOFFICE)}`, { otherGuaranteeComment });
            commit('SET_OTHER_GUARANTEE_COMMENT', otherGuaranteeComment);
        }
        catch (error) {
            return commit('SET_ERROR', 'Impossible de sauvegarder le commentaire');
        }
    },
    async fetchPriming({ state, commit }, id) {
        if (!state.info || !state.id)
            return;
        const insurer = process.env.INSURER;
        let url;
        if (id) {
            url = `/${insurer === 'generali' || insurer === 'lamedicale'
                ? 'generali_claim_primings'
                : 'claim_primings'}/${id}`;
        }
        else {
            const uuid = uuidFromIri(state.id);
            url = `${apiResourcePath(ApiResourceName.Claims)}/${uuid}/${insurer === 'generali' || insurer === 'lamedicale' ? 'generali-priming' : 'priming'}`;
        }
        const priming = await this.$axios.$get(url);
        commit('SET_PRIMING', priming);
    },
};
export const mutations = {
    RESET(state) {
        state.id = null;
        state.info = null;
        state.initialClaim = null;
        state.isInfoLoading = false;
        state.isEditingClaim = false;
        state.isSavingChanges = false;
        state.isContractLoading = false;
        state.contract = null;
        state.error = null;
        state.isUpdatingDraft = false;
        state.draftPayload = null;
        state.openClaimIndicator = null;
        state.entitiesChanges = newEntitiesChanges();
        state.hasPendingChanges = false;
        state.heliosDeclaration = null;
        state.heliosError = null;
        state.contractData = null;
        state.provision = null;
        state.otherFees = null;
        state.forcedExit = null;
        state.priming = null;
        state.magicLink = null;
    },
    SELECT_CLAIM(state, id) {
        state.id = id;
        state.isInfoLoading = true;
        state.error = null;
        state.isContractLoading = true;
        state.openClaimIndicator = null;
        state.heliosDeclaration = null;
        state.heliosError = null;
    },
    OPEN_INDICATOR(state, indicator) {
        state.openClaimIndicator = indicator;
    },
    CLOSE_INDICATOR(state) {
        state.openClaimIndicator = null;
    },
    SET_CONTRACT(state, contract) {
        state.isContractLoading = false;
        state.contract = contract;
    },
    SET_CONTRACT_DATA(state, contract) {
        state.contractData = contract;
    },
    SET_INITIAL_CLAIM(state, { claim, heliosDeclaration, heliosError }) {
        state.initialClaim = claim;
        state.heliosDeclaration = heliosDeclaration;
        state.heliosError = heliosError;
    },
    SET_INFO_CLAIM(state, claim) {
        if (state.info) {
            Vue.set(state.info, 'claim', claim);
            state.hasPendingChanges = stateHasPendingChanges(state);
        }
    },
    SET_INFO(state, info) {
        state.isInfoLoading = false;
        state.info = info;
        state.hasPendingChanges = stateHasPendingChanges(state);
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    EDIT(state) {
        state.isEditingClaim = true;
    },
    CANCEL_EDITION(state) {
        state.isUpdatingDraft = false;
        state.draftPayload = null;
        state.entitiesChanges = newEntitiesChanges();
        state.hasPendingChanges = false;
        state.isEditingClaim = false;
    },
    SET_UPDATING_DRAFT(state, updating) {
        state.isUpdatingDraft = updating;
    },
    SET_SAVING_CHANGES(state, saving) {
        state.isSavingChanges = saving;
    },
    RESET_CHANGES(state) {
        state.entitiesChanges = newEntitiesChanges();
        state.hasPendingChanges = false;
    },
    UPDATE_DRAFT_PAYLOAD(state, payload) {
        if (state.info && state.contract) {
            state.draftPayload = state.draftPayload ? mergePayload(state.draftPayload, payload) : payload;
            Vue.set(state.info, 'claim', mergePayload(state.info.claim, payload));
        }
    },
    ADD_ENTITY_CHANGE(state, changeInfo) {
        const entityChanges = state.entitiesChanges[changeInfo.type];
        applyEntityChange(entityChanges, changeInfo);
        state.hasPendingChanges = stateHasPendingChanges(state);
    },
    UNDO_ENTITY_CHANGE(state, { type, changeKey }) {
        const entityChanges = state.entitiesChanges[type];
        undoEntityChange({ entityChanges, changeKey });
        state.hasPendingChanges = stateHasPendingChanges(state);
    },
    TOUCH_AND_VALIDATE(state, { type, changeKey, modelKey, constraints, entity }) {
        const entityChanges = state.entitiesChanges[type];
        touchAndValidateEntity({ changeKey, modelKey, entityChanges, constraints, entity });
    },
    VALIDATE_AND_REVEAL(state, { changeKey, type, constraints, entity }) {
        const entityChanges = state.entitiesChanges[type];
        validateAndRevealEntity({ entityChanges, changeKey, constraints, entity });
    },
    SET_NOTE(state, note) {
        if (state.info)
            state.info.claim.note = note;
    },
    SET_OTHER_GUARANTEE_COMMENT(state, otherGuaranteeComment) {
        if (state.info)
            state.info.claim.otherGuaranteeComment = otherGuaranteeComment;
    },
    SET_PRIMING(state, priming) {
        state.priming = priming;
    },
    SET_MAGIC_LINK(state, payload) {
        state.magicLink = payload;
    },
};
